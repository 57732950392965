<template>
  <div class="pt-7 flex justify-center">
    <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-11/12 md:w-1/2">
      <div class="-mt-6 -ml-3 md:-ml-8 -mr-3 md:-mr-8 rounded-t-xl bg-sky-500 pt-4 pl-6 md:pl-8">
        <div class="leading-none tracking-tight mb-3 md:mb-4 text-2xl md:text-3xl text-gray-50">Company Login</div>
      </div>

      <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
        <span class="block sm:inline">{{ errorMessage }}</span>
      </div>

      <!-- Form Fields -->
      <div class="flex flex-wrap my-6">
        <div class="w-full">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="email">
            Email
          </label>
          <input @keydown.enter="submitLogin" class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="email" type="email" v-model="email">
        </div>
      </div>

      <div class="flex flex-wrap mb-6">
        <div class="w-full">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-password">
            Password
          </label>
          <input @keydown.enter="submitLogin" class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="password" type="password" v-model="password">
        </div>
      </div>

      <div class="flex items-end justify-between">
        <router-link to="/company/forgot-password">
          <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button">
            Forgot password
          </button>
        </router-link>

        <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button" @click="submitLogin">
          {{ SignInButtonText }}
        </button>
      </div>

      <!-- "Not Registered?" Section -->
      <div class="mt-6 text-center border-t pt-4">
        <p class="text-gray-700 text-sm">Not registered yet?</p>
        <router-link to="/company/register" class="text-sky-500 hover:text-sky-600 font-medium">
          Create a free account
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import companyService from '@/services/marketplace/company.service';

export default {
  name: 'Login',
  inject: ['toggleLoading'],
  components: {
  },
  data() {
    return {
      email:"",
      password: "",
      error: false,
      errorMessage: '',
      SignInButtonText: "Sign In"
    };
  },
  methods: {
    submitLogin() {
        this.toggleLoading(true);

        if (this.email != "" && this.password != "") {
          companyService.login(this.email, this.password)
              .then(response => {
                  if (response.data && response.data.error) {
                      this.error =  true;
                      this.errorMessage = 'We couldn\'t log you in. Please double-check your email and password, and try again. If the issue persists, contact support for assistance.'
                      this.toggleLoading(false);
                  } else {
                      // Set state
                      this.$store.commit('setCompanyLoggedIn', true)
                      this.$store.commit('setContractorLoggedIn' , false)

                      // Write token to localStorage
                      localStorage.setItem('companyToken', response.data.token);
                      localStorage.removeItem('contractorToken')

                      this.toggleLoading(false)
                      // Redirect to profiles
                      this.$router.push(this.$route.query.redirect_uri || '/company/dashboard');
                  }
              })
              .catch(error => {
                  // Handle error if the API call fails
                  this.error =  true;
                  this.errorMessage = 'There was an issue while submitting your information. Please try filling this form again in a few minutes'
                  this.toggleLoading(false);
            });
        } else {
            this.error = true
            this.toggleLoading(false);
            this.errorMessage = 'Please fill in the username and password fields.'
        }
    },
  },
  created() {
      const token = localStorage.getItem('companyToken');
      if (token) {
          // Optionally, validate the token if necessary
          this.$store.commit('setCompanyLoggedIn', true)
          this.$router.push('/company/dashboard');
      }
  }
}
</script>
