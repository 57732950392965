<template>
    <div>
        <!-- Step Navigation -->
        <div class="flex justify-center items-end mb-6">
            <div v-for="(step, index) in filteredSteps" :key="index" :class="{ 'w-1/3': isProject, 'w-1/2': !isProject }" class="px-1 flex flex-col items-center">
                <div class="w-full h-2 rounded-sm" :class="index <= currentStep ? 'bg-sky-500' : 'bg-gray-300'"></div>
                <div class="text-center text-xs mt-2" :class="index <= currentStep ? 'text-sky-500' : 'text-gray-500'">{{ step.name }}</div>
            </div>
        </div>
  
      <!-- Step Content -->
      <div v-show="currentStep === 0">
        <label for="industry" class="block text-gray-700 mb-1 font-semibold ml-0.5">Industry<span class="text-red-500">*</span></label>
        <select v-model="jobData.selectedIndustry" @change="fetchRoles" name="industry" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">Select industry</option>
            <option v-for="industry in industries" :key="industry.id" :value="industry.id">{{ industry.name }}</option>
        </select>

        <label for="role" class="block text-gray-700 mb-1 font-semibold ml-0.5">Role<span class="text-red-500">*</span></label>
        <select v-model="jobData.selectedRole" name="role" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">{{ !jobData.selectedIndustry ? 'Please select an industry first' : 'Please select a role' }}</option>
            <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
        </select>

        <!-- Job title -->
        <label for="jobTitle" class="block text-gray-700 mb-1 font-semibold ml-0.5">Job title<span class="text-red-500">*</span></label>
        <input v-model="jobData.title" type="text" name="jobTitle" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <!-- Job description -->
        <label for="jobDescription" class="block text-gray-700 mb-1 font-semibold ml-0.5">Job description<span class="text-red-500">*</span></label>
        <div class="rounded-lg border border-gray-300 overflow-hidden shadow-sm">
          <QuillEditor ref="quillEditor" @update:content="updateContent" @update:delta="updateDelta" id="jobDescription" class="h-96 " />
        </div>
      </div>
  
      <div v-show="currentStep === 1">
        <!-- Location -->
        <label for="work_location" class="block text-gray-700 mb-1 font-semibold ml-0.5">Location<span class="text-red-500">*</span></label>
        <input v-model="jobData.work_location" type="text" name="work_location" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <!-- Work type -->
        <label for="work_type" class="block text-gray-700 mb-1 font-semibold ml-0.5">Work type<span class="text-red-500">*</span></label>
        <select v-model="jobData.work_type" name="work_type" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">Select work type</option>
            <option value="casual">Casual</option>
            <option value="contract">Contract</option>
            <option value="full_time">Full Time</option>
            <option value="part_time">Part time</option>
        </select>

        <!-- Length of contract -->
        <div v-if="jobData.work_type == 'contract'">
            <label for="contract_length" class="block text-gray-700 mb-1 font-semibold ml-0.5">Length of contract<span class="text-red-500">*</span></label>
            <input v-model="jobData.contract_length" type="text" name="contract_length" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />
        </div>
        
        <div class="w-full">
            <!-- Label for the entire input -->
            <label for="start_date_group" class="block text-gray-700 mb-1 font-semibold ml-0.5">
              Start date<span class="text-red-500">*</span>
            </label>

            <!-- Input Group -->
            <div id="start_date_group" class="flex items-center w-full p-4 space-x-4 border rounded-lg p-2.5 mb-2.5">
              <!-- ASAP Option -->
              <div class="flex-1">
                <label class="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="radio"
                    id="asap"
                    name="start_date"
                    value="asap"
                    v-model="startDateOption"
                    @change="handleStartDateChange"
                    class="hidden peer"
                  />
                  <span
                    class="flex select-none items-center justify-center w-full py-2 border-b border-gray-300 text-gray-700 peer-checked:border-sky-500 peer-checked:text-sky-500"
                  >
                    ASAP
                  </span>
                </label>
              </div>

              <!-- OR Divider -->
              <div class="text-gray-400 font-medium text-sm">OR</div>

              <!-- Date Picker Option -->
              <div class="flex-1">
                <label class="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="radio"
                    id="pick_date"
                    name="start_date"
                    value="pick_date"
                    v-model="startDateOption"
                    @change="handleStartDateChange"
                    @click="handleStartDateChange"
                    class="hidden peer"
                  />
                  <span
                    class="flex select-none items-center justify-center w-full py-2 border-b border-gray-300 text-gray-700 peer-checked:border-sky-500 peer-checked:text-sky-500"
                  >
                    {{ startDateString }}
                  </span>
                </label>

                <Popover v-if="calendarOpen" class="relative">
                    <PopoverPanel class="absolute z-10">
                        <Datepicker class="my-3" v-model="jobData.start_date" :min-date="today" inline autoApply @update:modelValue="updateCalendarDate" hideOffsetDates :enableTimePicker="false" :inline="true" ref="datepicker"></Datepicker>
                    </PopoverPanel>
                </Popover>
              </div>
            </div>
        </div>

        <!-- New rate -->
        <div class="flex flex-col md:flex-row md:space-x-4">
            <div class="flex flex-col w-full md:w-1/3">
                <label for="rate_category" class="block text-gray-700 mb-1 font-semibold ml-0.5">Rate</label>

                <select v-model="jobData.rate_category" name="rate_category" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
                  <option value="undisclosed">Undisclosed</option>
                  <option value="custom_rate">Custom rate</option>
                  <option value="award">Award</option>
                  <option value="negotiable">Negotiable</option>
                  <option value="market_rate">Market rate</option>
              </select>
            </div>
            <div v-show="jobData.rate_category == 'custom_rate'" class="flex flex-col md:flex-row w-full md:w-2/3 md:space-x-4">
                <div class="flex flex-col w-full md:w-1/2">
                    <label for="rate" class="block text-gray-700 mb-1 font-semibold ml-0.5">Rate amount <span class="text-red-500">*</span></label>
                    <input v-model="jobData.rate" name="rate" type="number" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />
                </div>
                <div class="flex flex-col w-full md:w-1/2">
                    <label for="rate" class="block text-gray-700 mb-1 font-semibold ml-0.5">Rate type <span class="text-red-500">*</span></label>
                    <select v-model="jobData.rate_type" name="rateType" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
                        <option disabled value="">Select rate type</option>
                        <option value="hour">Hourly</option>
                        <option value="day">Daily</option>
                    </select>
                </div>
            </div>
        </div>

        <label for="rate" class="block text-gray-700 mb-1 font-semibold ml-0.5">Work Arrangement<span class="text-red-500">*</span></label>
        <select v-model="jobData.work_arrangement" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">Select work arrangement</option>
            <option value="field">Field</option>            
            <option value="hybrid">Hybrid</option>
            <option value="office">Office / On Site</option>
            <option value="wfh">WFH</option>
            <option value="other">Other</option>
        </select>

        <label for="roster" class="block text-gray-700 mb-1 font-semibold ml-0.5">Roster</label>
        <input v-model="jobData.roster" type="text" name="roster" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <label for="hours" class="block text-gray-700 mb-1 font-semibold ml-0.5">Hours</label>
        <input v-model="jobData.hours" type="text" name="hours" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />


        <label for="isProject" class="block text-gray-700 mb-1 font-semibold ml-0.5">Is this for a project?<span class="text-red-500">*</span></label>
        <select v-model="isProject" name="isProject" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">Select an option</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
      </div>
  
      <div v-if="currentStep === 2">
        <label for="project_description" class="block text-gray-700 mb-1 font-semibold ml-0.5">Project description<span class="text-red-500">*</span></label>
        <textarea v-model="jobData.project_description" type="text" name="project_description" rows="5" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <label for="project_timeframe" class="block text-gray-700 mb-1 font-semibold ml-0.5">Project timeframe</label>
        <input v-model="jobData.project_timeframe" type="text" name="project_timeframe" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <label for="project_stage" class="block text-gray-700 mb-1 font-semibold ml-0.5">Current project stage</label>
        <input v-model="jobData.project_stage" type="text" name="project_stage" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <div class="flex space-x-4">
            <div class="w-1/2 flex flex-col">
                <label for="project_team_size" class="block text-gray-700 mb-1 font-semibold ml-0.5">Project team size</label>
                <input v-model="jobData.project_team_size" type="text" name="project_team_size" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />
            </div>
            <div class="w-1/2 flex flex-col">
                <label for="project_budget" class="block text-gray-700 mb-1 font-semibold ml-0.5">Project budget</label>
                <input v-model="jobData.project_budget" type="text" name="project_budget" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />
            </div>
        </div>
      </div>
  
      <!-- Navigation Buttons -->
      <div class="flex justify-between mt-6">
        <button v-if="currentStep > 0" @click="previousStep" class="border border-sky-500 hover:bg-sky-100 text-gray-500 py-2 px-4 rounded">
          Previous
        </button>
        <button v-if="(!isProject && currentStep < totalSteps - 2) || (isProject && currentStep < totalSteps - 1)" @click="nextStep" class="bg-sky-500 hover:bg-sky-400 text-white px-4 py-2 rounded-md ml-auto">
          Next
        </button>
        <button v-if="(isProject && currentStep === totalSteps - 1) || (!isProject && currentStep === totalSteps - 2)" @click="submitJob" class="bg-green-500 text-white px-4 py-2 rounded-md ml-auto">
          Submit
        </button>
      </div>
    </div>
  </template>
  
<script>
import 'quill/dist/quill.snow.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import QuillEditor from '@/components/marketplace/generic/QuillEditor.vue';

import genericService from '@/services/marketplace/generic.service'

export default {
    name: 'JobForm',
    inject: ['toggleLoading'],
    components: {
      QuillEditor,
      Datepicker
    },
    computed: {
        filteredSteps() {
            return this.isProject ? this.steps : this.steps.slice(0, -1);
        },
    },
    data() {
      return {
        today: new Date(),
        selectedDate: 0,
        isProject: '',
        startDateString: 'Pick a date',
        calendarOpen: false,
        startDateOption: '',
        currentStep: 0,
        totalSteps: 3,
        steps: [
          { name: 'Role Description' },
          { name: 'Conditions' },
          { name: 'Project details (optional)' },
        ],
        jobData: {},
        error: false,
        errorMessage: '',
        industries: [],
        roles: [],
      };
    },
    methods: {
      async fetchIndustries() {
          this.toggleLoading(true);
          try {
            const response = await genericService.getIndustries();
            this.industries = response.data;
            this.toggleLoading(false);
          } catch (error) {
            console.error('Error fetching industries:', error);
            this.toggleLoading(false);
          }
      },
      async fetchRoles() {
          this.toggleLoading(true);
          this.roles = [];
          this.jobData.selectedRole = '';

          if (this.jobData.selectedIndustry) {
              try {
                  const response = await genericService.getRoles(this.jobData.selectedIndustry);
                  this.roles = response.data;
                  this.toggleLoading(false);
              } catch (error) {
                  console.error('Error fetching roles:', error);
                  this.toggleLoading(false);
              }
          }
      },
      updateContent(newContent) {
        this.jobData.description_rendered = newContent
      },
      updateDelta(delta) {
          this.jobData.description = delta
      },
      initiateDefaultValues() {
        this.jobData.work_type = this.jobData.work_type !== undefined ? this.jobData.work_type : '';
        this.jobData.rate_type =  this.jobData.rate_type !== undefined ? this.jobData.rate_type : '';
        this.jobData.work_arrangement = this.jobData.work_arrangement !== undefined ? this.jobData.work_arrangement : '';
        this.jobData.selectedIndustry = this.jobData.selectedIndustry !== undefined ? this.jobData.selectedIndustry : '';
        this.jobData.selectedRole = this.jobData.selectedRole !== undefined ? this.jobData.selectedRole : '';
        this.jobData.rate_category = this.jobData.rate_category !== undefined ? this.jobData.rate_category : 'undisclosed'
      },
      handleStartDateChange() {
          if (this.startDateOption === 'pick_date') {
              // Open the calendar for "Pick a date"
              this.calendarOpen = true;
          } else if (this.startDateOption === 'asap') {
              // Reset for "ASAP"
              this.calendarOpen = false;
              this.startDateString = 'Pick a date';
              this.selectedDate = 1;
          } else {
              // Close the calendar for other options
              this.calendarOpen = false;
          }
      },
      updateCalendarDate(newDate) {
        this.selectedDate = (newDate.getTime() / 1000)
        this.calendarOpen = false

        const formattedDate = newDate.toLocaleDateString('en-AU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });

        this.startDateString = 'From ' + formattedDate
    },
      stepClass(index) {
        if (this.currentStep === index) {
          return 'text-sm px-4 py-2 bg-sky-500 text-white';
        }
        return index < this.currentStep ? 'text-sm px-4 py-2 bg-green-500 text-white' : 'cursor-pointer text-gray-700';
      },
      goToStep(index) {
        if (index <= this.currentStep) {
          this.currentStep = index;
        }
      },
      nextStep() {
        if (this.validateCurrentStep()) {
          this.currentStep++;
        }
      },
      previousStep() {
        if (this.currentStep > 0) {
            this.currentStep--;
        }
      },
      validateCurrentStep() {
        this.error = false
        this.errorMessage = ''

        if (this.currentStep === 0 && (!this.jobData.selectedIndustry || !this.jobData.selectedRole || !this.jobData.title || !this.jobData.description || !this.jobData.description.ops || !this.jobData.description.ops.length)) {
            this.error = true
        } else if (this.currentStep === 1) {
            if (this.isProject === '') {
                this.error = true;
                this.errorMessage = 'Please select if this job post is for a project'
            }

            if (!this.jobData.work_arrangement) {
                this.error = true;
                this.errorMessage = 'Please select a work arrangement'
            }

            if (this.jobData.rate_category == 'custom_rate' && (!this.jobData.rate || !this.jobData.rate_type)) {
                this.error = true;
                this.errorMessage = 'The Rate Amount and Rate Type fields are mandatory when using a custom rate'
            }

            if (this.selectedDate == 0) {
                this.error = true;
                this.errorMessage = 'Please select a valid start date'
            }

            if (!this.jobData.contract_length && this.jobData.work_type && this.jobData.work_type == 'contract') {
                this.error = true;
                this.errorMessage = 'You must provide a contract length for this work type'
            }

            if (!this.jobData.work_type) {
                this.error = true;
                this.errorMessage = 'Please select work type'
            }

            if (!this.jobData.work_location) {
                this.error = true;
                this.errorMessage = 'The Location field is mandatory'
            }
        } else if (this.currentStep === 2 && (!this.jobData.project_description)) {
            this.error = true
        }

        if (this.error == true) {
            if (this.errorMessage == '') {
              this.$root.notify('Please fill out all the mandatory fields.', 'error')
            } else {
              this.$root.notify(this.errorMessage, 'error')
            }
            return false;
        }

        return true;
      },
      submitJob() {
        if (this.validateCurrentStep()) {
          this.jobData.isProject = this.isProject
          this.jobData.start_date = this.selectedDate
          this.$emit('job-submitted', this.jobData);
        }
      },
    },
    mounted() {
      this.initiateDefaultValues();
      this.fetchIndustries();
    },
  };
  </script>
  
  <style scoped>
  .input-field {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  button {
    transition: all 0.3s ease;
  }


  </style>
